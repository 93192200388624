module.exports = {
    desktopAnimStarted: false,
    controller: null,
    init: function (ScrollMagic, TweenMax) {
        qsn.controller = new ScrollMagic.Controller();


        // scrollmagic "responsabilité sociale / environnement"
        if($(window).width() >= 1000) {
            qsn.startDesktopAnims(ScrollMagic, TweenMax);
        }
        $(window).on("resize", function() {
            if($(window).width() >= 1000 && qsn.desktopAnimStarted === false) {
                qsn.startDesktopAnims(ScrollMagic, TweenMax);
            }
        });


        // carousel
        $('#qsn-carousel').slick({
            dots: true,
            infinite: true,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 4000,
            pauseOnFocus: false,
            pauseOnHover: false,
            rows: 0
        });

        $(".section-title").each(function() {
            let scene = new ScrollMagic.Scene({
                triggerElement: $(this).parent()[0],
                triggerHook: 0,
                duration: $(this).parent().height() - 98
            })
                .setPin(this, {pushFollowers: false})
                .addTo(qsn.controller);
        });
    },
    startDesktopAnims: function(ScrollMagic, TweenMax) {
        qsn.desktopAnimStarted = true;
        // Valeurs
        $('#val-slider').slick({
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            /*
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnFocus: false,
        pauseOnHover: false,
        */
            rows: 0
        });
        $("#val-slider a.cta").on('click', function(e) {
            e.preventDefault();
            $('#val-slider').slick('slickGoTo', $(e.currentTarget).attr('data-slideIdx'));
        });
        $repere = $("#img-large-before img");
        $beforeCont = $("#img-large-before");
        let rw = $repere.width();
        if (rw < 10)
            rw = 180;
        let left = $repere.offset().left + rw;
        //let width = rw * 1.5;
        let width = $("#img-large-before > .row > .d-none").width() - rw;
        let top = -($beforeCont.outerHeight() + ($beforeCont.offset().top - $repere.offset().top) - 80);
        let height = width * 4 / 3;
        let tween = TweenMax.fromTo("#il-bg", 1, {left: left, width: width, top: top, height: height}, {
            left: 0,
            width: "100%",
            top: 0,
            height: "100%",
            ease: Sine.easeInOut
        });
        new ScrollMagic.Scene({
            "triggerElement": "#img-large",
            "triggerHook": "onEnter",
            "duration": "600"
        })
            .setTween(tween)
            .addTo(qsn.controller);
    }
};