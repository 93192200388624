module.exports = {
    accept: false,
    init: function () {
        $dest = $("select#destinataire");
        console.log('testcontact');
        $dest.on('change', function () {
            console.log($dest.val());
            $("input#contact_destinataire").val($dest.val());
        });

        $("div[data-f='adresse']").hide();
        $("div[data-f='cptcha'] label span").html($("div[data-f='adresse']").attr('data-c'));

        $("form[name='contact']").on('submit', function (e) {
            if (!contact.accept)
                e.preventDefault();
        })

        $("input#send").hide();
        $("#enablesubmit").on('click', function () {
            if($("#enablesubmit").is(":checked")) {
                contact.accept = true;
                $("input#send").show();
            }
            else {
                contact.accept = false;
                $("input#send").hide();
            }
        });
    }
};