module.exports = {
    init: function () {
        $("form[name='newsletter_subscribe']").on('submit', function (e) {
            e.preventDefault();
            if(util.emailIsValid($("#newsletter_subscribe_email").val())) {
                $("form[name='newsletter_subscribe'], form[name='newsletter_subscribe'] input, form[name='newsletter_subscribe'] button").attr('disabled', 'disabled');
                $("form[name='newsletter_subscribe'] button").html("...");
                $.ajax({
                    url: path.root + "ajax/nlsubscribe",
                    method: "post",
                    data: {"email": $("#newsletter_subscribe_email").val()}
                }).done(newsletter.callback)
                .fail(newsletter.failcallback);
            }
            else {
                $("form[name='newsletter_subscribe'] button").html("❌");
                $(".newsletter_subscribe_feedback").addClass("error-email");
            }
        });
        $("#newsletter_subscribe_email").on('keyup click change', function() {
            $("form[name='newsletter_subscribe'] button").html("ok");
            $(".newsletter_subscribe_feedback").removeClass("sent");
            $(".newsletter_subscribe_feedback").removeClass("error-email");
            $(".newsletter_subscribe_feedback").removeClass("error-network");
        })
    },
    callback: function (data) {
        $("form[name='newsletter_subscribe']").addClass("sent");
        $(".newsletter_subscribe_feedback").addClass("sent");
        $("form[name='newsletter_subscribe'] button").html("✔️");
    },
    failcallback: function() {
        $("form[name='newsletter_subscribe'] button").html("❌");
        $(".newsletter_subscribe_feedback").addClass("error-network");
    }
};