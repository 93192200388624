module.exports = {
    init: function () {
        // Hamburger
        $(".navbar-toggler").on('click', function () {
            $(".mobilemenu").toggleClass('open');
            $(".navbar-toggler").toggleClass('open');
            $("body").toggleClass('menu-open');
        });
        $(".mobilemenu").on('click', '.deeper', function (e) {
            e.preventDefault();
            mobile.menu.translate(-1);
            if ($(e.currentTarget).attr('data-lvl2')) {
                $(".translate .block:nth-child(2) ul").hide();
                $(".translate .block:nth-child(2) ul[data-list=" + $(e.currentTarget).attr('data-lvl2') + "]").show();
                $(".mobilemenu .level2 .blocknav .title").html($(e.currentTarget).parent().find('a:first-child').html());
                if ($(e.currentTarget).attr('data-lvl2') == "produits") {

                }

            }
            if ($(e.currentTarget).attr('data-level')) {
                let slug = $(e.currentTarget).attr('data-slug');
                let level = parseInt($(e.currentTarget).attr('data-level'));
                console.log("setting tree level", slug, level);
                if (level == 1) {
                    productmenu.menu2.set(slug);
                }
                else {
                    productmenu.menu2.setActiveChild(level - 1, slug, productmenu.activeChild[level - 2]);
                }
                mobile.menu.drawLevel(level - 1);
            }
        });

        $(".mobilemenu").on('click', 'ul a:not(.deeper)', function (e) {
            $(".mobilemenu").toggleClass('open');
            $(".navbar-toggler").toggleClass('open');
        });
        $(".mobilemenu .back").on('click', function (e) {
            e.preventDefault();
            $(".mobilemenu .block").scrollTop(0);
            mobile.menu.translate(1);
        });
    },
    menu: {
        position: 0,
        isProductMenu: false,
        translate: function (n) {

            mobile.menu.position += 100 * n;
            $(".mobilemenu .translate").css('transform', 'translate(' + mobile.menu.position + 'vw)');
        },
        drawLevel: function (level) {
            let data = productmenu.activeChild[level];
            console.log('draw level', level, data);
            let $block = $(".block[data-level='" + (level + 1) + "']");
            let $list = $block.find('ul');
            $list.html('');
            $block.find('.blocknav .title').html(data.title);
            for (let i = 0; i < data.children.length; i++) {
                console.log(data.children[i]);
                let child = data.children[i];
                let $li = $("<li></li>");
                let isExternal = child.external != "";
                if(isExternal)
                    $li.append('<a href="' + child.external + '" class="external white" target="_blank">' + child.title + '</a>');
                else
                    $li.append('<a href="' + path.prodcat + child.id + '/' + child.slug + '">' + child.title + '</a>');
                if (child.children.length > 0 && !isExternal)
                    $li.append('<a href="" class="deeper" data-level="' + (level + 2) + '" data-slug="' + child.slug + '"> &gt; </a>');
                $list.append($li);
            }

        }
    }
};