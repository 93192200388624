module.exports = {
    cookiesSet: false,
    devmode: true,
    init: function() {
        // 1 - vérifier si des instructions ont été enregistrées

        RGPD.doNotTrack = navigator.doNotTrack || navigator.msDoNotTrack;
        RGPD.cookiesSet = RGPD.getConsentCookie();

        // 2 - en l'absence d'instructions, ou s'il en manque, afficher le bandeau
        if(!RGPD.allSet())
            $("#rgpd").addClass('active');
        // 2b - si instructions, et si on est sur la page RGPD, mettre à jour les boutons
        RGPD.updateButtons();

        // 3 - gérer les boutons de réglages
        $(".rgpd-btn").on('click', RGPD.buttonClick);
        $(".rgpd-enable-all").on('click', RGPD.enableAll);
        $(".rgpd-disable-all").on('click', RGPD.disableAll);

        // 4 - enfin, selon les valeurs récupérées, activer les cookies autorisés.
        RGPD.activation();
    },
    // Activation de tous les services pour lesquels le consentement a été obtenu.
    activation: function() {
        for (let prop in RGPD.cookies) {
            let cookie = RGPD.cookies[prop];
            if(cookie.isSet && cookie.allowed && !cookie.enabled)
                cookie.enable();
        }
    },
    // clic sur un des boutons de réglage (classe .rgpd-btn)
    buttonClick: function(e) {
        $(e.currentTarget).parent().find('button').removeClass('active');
        $(e.currentTarget).addClass('active');
        RGPD.updateSettings();
        RGPD.setConsentCookie();
    },
    setAll: function(action) {
        $(".rgpd-btn").removeClass('active');
        for (let prop in RGPD.cookies) {
            let cookie = RGPD.cookies[prop];
            $("button[data-item='"+prop+"'][data-action='"+action+"']").addClass('active');
            cookie.isSet = true;
            cookie.allowed = (action == "enable");
        }
        RGPD.setConsentCookie();
        $("#rgpd").removeClass('active');
    },
    enableAll: function() {
        RGPD.setAll('enable');
    },
    disableAll: function() {
        RGPD.setAll('disable');
    },
    // mise à jour des réglages selon les états des boutons
    updateSettings: function() {
        for (let prop in RGPD.cookies) {
            let cookie = RGPD.cookies[prop];
            if($("button[data-item='"+prop+"'].active").length > 0) {
                cookie.isSet = true;
                cookie.allowed = $("button[data-item='"+prop+"'].active").attr('data-action') == "enable";
            }
        }
        if(RGPD.buttonsAllSet()) {
            RGPD.activation();
            $("#rgpd").removeClass('active');
        }
    },
    // Mise à jour des boutons selon les cookies récupérés
    updateButtons: function() {
        for (let prop in RGPD.cookies) {
            let cookie = RGPD.cookies[prop];
            if(cookie.isSet) {
                let dA = cookie.allowed ? 'enable' : 'disable';
                $("button[data-item='"+prop+"'][data-action='"+dA+"']").addClass('active');
            }
        }
    },
    // Vérifie l'existence des cookies de consentement et récupère leur état.
    getConsentCookie: function(){
        let raw = Cookies.get('rgpd');
        if(typeof raw !== "undefined") {
            let consentCookie = JSON.parse(Cookies.get('rgpd')) ;
            for (let prop in consentCookie) {
                if(typeof RGPD.cookies[prop] !== "undefined") {
                    RGPD.cookies[prop].isSet = true;
                    RGPD.cookies[prop].allowed = consentCookie[prop];
                }
            }
            return true;
        }
        else
            return false;
    },
    // enregistre les réglages courants des cookies de consentement
    setConsentCookie: function() {
        let jsonCookie = {};
        for (let prop in RGPD.cookies) {
            let cookie = RGPD.cookies[prop];
            if(cookie.isSet)
                jsonCookie[prop] = cookie.allowed;
        }
        let rawcookie = JSON.stringify(jsonCookie);
        if(rawcookie != "{}")
            Cookies.set('rgpd', rawcookie, { expires: 395 });
    },
    // Pour chaque service : état du cookie de consentement et procédure d'activation.
    cookies: {
        /*
        "nomduservice": {
            isSet : le cookie existe.
            allowed: le cookie vaut true (l'utilisateur accepte son activation)
            enabled: true quand le service a été activé via la fonction enable()
            enable: la fonction en question
            disable: quand le service propose un processus de désactivation, cette fonction est appelée quand le user clique sur "refuser".
        }
         */
        /*
        "googleanalytics" : {
            isSet : false,
            allowed : false, // true : le user consent à l'activation du cookie
            enabled: false,  // Le cookie est activé
            enable: function() {
                if(!RGPD.devmode) {
                    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
                            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
                        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
                    })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
                    ga('create', 'UA-4954321-37', {'cookieExpires': 34128000}); // 13 mois au lieu des 24 par défaut (1 an + 30 jours)
                    ga('send', 'pageview');
                }
                RGPD.cookies.googleanalytics.enabled = true;
            },
            disable: function() {
                if(!RGPD.devmode) {
                    let GA_PROPERTY = 'UA-4954321-37';
                    let GA_COOKIE_NAMES = ['__utma', '__utmb', '__utmc', '__utmz', '_ga', '_gat'];
                    Cookies.set(`ga-disable-${GA_PROPERTY}`, true, { expires: 395 });
                    window[`ga-disable-${GA_PROPERTY}`] = true;
                    GA_COOKIE_NAMES.forEach(cookieName => Cookies.remove(cookieName));
                }
                RGPD.cookies.googleanalytics.enabled = false;
            }
        },*/
        "gtag" : {
            isSet : false,
            allowed : false,
            enabled: false,
            enable: function() {
                if(!RGPD.devmode) {
                    $("head").append('<script async src="https://www.googletagmanager.com/gtag/js?id=G-R13MKV3X8V"></script>');
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-R13MKV3X8V');
                }
                RGPD.cookies.gtag.enabled = true;
            },
            disable: function() {
                if(!RGPD.devmode) {
                }
                RGPD.cookies.gtag.enabled = false;
            }
        }
    },
    allSet: function() {
        for (let prop in RGPD.cookies) {
            let cookie = RGPD.cookies[prop];
            if(!cookie.isSet)
                return false;
        }
        return true;
    },
    buttonsAllSet: function() {
        for (let prop in RGPD.cookies) {
            let cookie = RGPD.cookies[prop];
            if(!cookie.isSet)
                return false;
        }
        return true;
    }
};