module.exports = {
    data: {},
    lastIdx: {},
    init: function () {
        this.data.labels = {};
        this.data.content = {};
        for (let l in locales) {
            let locale = locales[l];
            let textarea = $("#declinaison_translations_" + locale + "_data");
            let raw = textarea.val();
            textarea.hide();
            console.log('parsing: ', raw);
            if (!raw) raw = "{}";
            let localeData = JSON.parse(raw);

            console.log('locale: ', locale);
            let labels = [];
            let content = [];
            for (var i in localeData) {
                labels.push(i);
                content.push(localeData[i]);
            }
            this.data.labels[locale] = labels;
            this.data.content[locale] = content;
        }
        this.fill();
        $(".tab-content").on('change keyup', 'input', declinaisons.update);
        $("#decl-addfield").on('click', function (e) {
            e.preventDefault();
            for (let l in locales) {
                declinaisons.addField("", "", locales[l]);
            }
        })
    },

    addField: function (key, val, lang, idx) {
        if (typeof idx === "undefined")
            idx = declinaisons.lastIdx[lang] + 1;
        $("#declinaison_translations_" + lang).append('<div class="form-group form-inline" data-idx="' + idx + '" data-lang="' + lang + '"><input class="form-control" type="text" value="' + key + '"><input class="form-control" type="text" value="' + val + '"></div>');
        declinaisons.lastIdx[lang] = idx;
    },

    fill: function () {
        let nbFields = this.data.labels[locales[0]].length;
        console.log(nbFields, "fields.");
        for (let i = 0; i < nbFields; i++) {
            for (let l in locales) {
                this.addField(this.data.labels[locales[l]][i], this.data.content[locales[l]][i], locales[l], i);
            }
        }
    },
    update: function () {
        for (let l in locales) {
            let str = "";
            let locale = locales[l];
            $("#declinaison_translations_" + locale + ' .form-inline[data-idx]').each(function () {
                let key = $(this).find('input:nth-child(1)').val().trim();
                let val = $(this).find('input:nth-child(2)').val().trim();
                if (key != "" && val != "")
                    str += '"' + key + '": ' + '"' + val + '",'
            });
            str = "{" + str.substring(0, str.length - 1) + "}";
            console.log(locale, str);
            $("#declinaison_translations_" + locale + "_data").val(str);
        }
    }
};