/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
//require('../css/app.css');
require('../css/app.scss');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
global.$ = global.jQuery = require('jquery');
require('bootstrap');
require('datatables.net-bs4');
require('slick-carousel');

import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems
import {TweenMax, TimelineMax} from "gsap"; // Also works with TweenLite and TimelineLite
import {ScrollMagicPluginGsap} from "scrollmagic-plugin-gsap";

global.Cookies = require('js-cookie/src/js.cookie');

global.Swiper = require('swiper/js/swiper');

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/silver/theme';

global.util = require('./util');
global.newsletter = require('./front/newsletter');
newsletter.init();

global.mobile = require('./front/mobile');
mobile.init();

// categories
global.categories = require('./back/categories');
categories.init();

// single product
global.products = require('./front/products');
products.init();

// front product menu
if ($("#homeCarousel").length != 0) {
    global.homepage = require('./front/homepage');
    homepage.init(ScrollMagic, TweenMax);
}
// page qsn
if ($(".page-qsn").length != 0) {
    global.qsn = require('./front/qsnpage');
    qsn.init(ScrollMagic, TweenMax);
}

let isFront = $(".backoffice").length == 0;


if (!isFront) {
    // back tables
    $(".table:not(.nodata)").DataTable();
    // back tinymce
    tinymce.init({
        selector: 'textarea:not(.intro):not([id*=decl]):not(.noeditor)',
        plugins: 'lists link paste',
        toolbar: 'undo redo | numlist bullist | bold italic | link | removeformat | styleselect ',
        menubar: false,
        relative_urls: false,
        remove_script_host: false,
        convert_urls: false,
        paste_as_text: true,
        paste_preprocess: function (plugin, args) {
            args.content = args.content.trim().replace(/<p><\/p>/gi, '').replace(/<p><br \/>/gi, '<p>');
        },
        style_formats: [
            {'title': 'Title', 'block': 'h3'},
            {'title': 'Paragraph', 'block': 'p'},
            {'title': 'Blue', 'inline': 'span', 'classes': 'blue'},
            {'title': 'Grey', 'inline': 'span', 'classes': 'grey'}
        ],
        content_style: '.blue {color: #285771;} .grey {color: #707173} h3 {font-size: 16px;color:#285771;font-family: "Variable-Black", "Trebuchet MS", sans-serif;}'
    });
    tinymce.init({
        selector: 'textarea.intro',
        plugins: 'lists link paste',
        toolbar: 'undo redo | bold italic | link | removeformat | styleselect ',
        menubar: false,
        relative_urls: false,
        remove_script_host: false,
        convert_urls: false,
        paste_as_text: true,
        paste_preprocess: function (plugin, args) {
            args.content = args.content.trim().replace(/<p><\/p>/gi, '').replace(/<p><br \/>/gi, '<p>');
        },
        style_formats: [
            {'title': 'Title', 'block': 'h2'},
            {'title': 'Paragraph', 'block': 'p'}
        ]
    });
    // file upload labels

    $('input[type="file"]').on('change', function (event) {
        let filenames = "";
        for(let i = 0; i < event.target.files.length; i++) {
            if(i > 0) filenames += ", ";
            filenames += event.target.files[i].name;
        }
        $(this).next('.custom-file-label').html(filenames);
    });

    // déclinaisons
    if ($(".decl-form").length != 0) {
        global.declinaisons = require('./back/declinaisons');
        declinaisons.init();
    }
    // produits
    global.backproducts = require('./back/products');
    backproducts.init();
    // produits
    console.log('requiring localetabs...');
    global.localetabs = require('./back/localetabs');
    localetabs.init();

    // selecteur style homeslider
    let homecarousel = $(".homecarousel");
    if(homecarousel.length > 0) {
        $(".homecarousel .card[data-idx='"+homecarousel.attr('data-active')+"']").addClass('active');
        $(".homecarousel .card").on('click', function(e) {
            $(".homecarousel .card").removeClass('active');
            $(e.currentTarget).addClass('active');
            $("#hp_slide_color").val($(e.currentTarget).attr('data-idx'));
        });
    }
}
else { // front
    // product menu
    global.productmenu = require('./front/productmenu');
    productmenu.init();

    // front product page
    if ($(".product-page").length != 0) {
        global.productpage = require('./front/productpage');
        productpage.init();
    }

    if($("form[name='contact']").length > 0) {
        global.contact = require('./front/contact');
        contact.init();
    }

    //RGPD
    global.RGPD = require('./front/rgpd');
    RGPD.devmode = isDebug;
    RGPD.init();
}


// scroll to element
$("[data-target]").on('click', function (e) {
    let $target = $($(e.currentTarget).attr('data-target'));
    let duration = 500;
    $('html, body').animate({scrollTop: $target.offset().top - 42}, duration, "swing"); // +42 : header height
});

// scrolled class
$(window).on('scroll', function () {
    if ($(window).scrollTop() > 0)
        $("body").addClass('scrolled');
    else
        $("body").removeClass('scrolled');
});

// HIGHLIGHT UNTRANSLATED TERMS
//$( "*:contains('__')" ).css( "background", "#FF0" );
window.getNodesThatContain = function(text) {
    let textNodes = $(document).find(":not(iframe, script)")
        .contents().filter(
            function() {
                return this.nodeType == 3
                    && this.textContent.indexOf(text) > -1;
            });
    return textNodes.parent();
};
let $nodes = window.getNodesThatContain("__");
$nodes.each(function() {
    $(this).css({'background': '#FF0', 'color': '#000'});
});