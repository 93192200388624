module.exports = {
    init: function () {
        backproducts.search.init();
    },
    search: {
        lines: $("#results table tr"),
        init: function () {
            backproducts.search.lines.hide();
            $("#search").on('change keyup', backproducts.search.update);
        },
        update: function (e) {
            let term = $("#search").val().trim();
            let count = 0;
            backproducts.search.lines.hide();
            if (term != "") {
                backproducts.search.lines.each(function (index, elt) {
                    if (count > 10)
                        return false;
                    let ref = $(this).attr('data-ref');
                    let title = $(this).attr('data-title');
                    if (ref.toLowerCase().indexOf(term.toLowerCase()) >= 0 || title.toLowerCase().indexOf(term.toLowerCase()) >= 0) {
                        $(this).show();
                        count++;
                    }
                    else
                        $(this).hide();
                });
            }
        }
    }
};