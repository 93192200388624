module.exports = {
    init: function() {
        if($("form[name='category']").length > 0)
            this.categoryform();
        if($("form[name='product']").length > 0)
            this.productform();
        if($("form[name='service']").length > 0)
            this.serviceform();
    },
    categoryform: function () {
        console.log("catfom");
        this.sync($("#categorySelect"), $("input[name='category[parentNode]']"), $(".newcat-h1").length == 0);
    },
    productform: function () {
        console.log("prodfom");
        this.sync($("#categorySelect"), $("#product_category"), $(".newprod-h1").length == 0);
    },
    serviceform: function () {
        console.log("prodfom");
        this.sync($("#categorySelect"), $("#service_category"), $(".newprod-h1").length == 0);
    },
    sync: function(formatted, source, syncOnLoad) {
        console.log("syncing: ", formatted, source, formatted.val(), source.val(), syncOnLoad);
        formatted.on('change', function() {
            source.val(formatted.val());
        });
        if(syncOnLoad)
            formatted.val(source.val());
        else
            source.val(formatted.val());
    }
};