module.exports = {
    init: function (ScrollMagic, TweenMax) {
        // Top carousel
        $('#homeCarousel').slick({
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            rows: 0,
            autoplay: true,
            autoplaySpeed: 7000,
            pauseOnFocus: false,
            pauseOnHover: false,
        });

        // Specialites carousel
        /*
            $('#specialites').slick({
                infinite: true,
                arrows: false,
                slidesToShow: 5,
                slidesToScroll: 4,
                centerMode: true,
                centerPadding: '10rem',
                autoplay: true,
                autoplaySpeed: 4000,
                pauseOnFocus: false,
                pauseOnHover: false,
                responsive: [
                    {
                        breakpoint: 1025,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            centerPadding: '5rem'
                        }
                    },
                    {
                        breakpoint: 800,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            centerPadding: '8rem'
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            centerPadding: '2rem'
                        }
                    }
                ]
            });
        */
        let specSwiper = new Swiper("#specialites", {
            slidesPerView: 1,
            loop: true,
            centeredSlides: true,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
            },
            breakpoints: {
                600: {
                    slidesPerView: 2
                },
                800: {
                    slidesPerView: 3
                },
                1025: {
                    slidesPerView: 5
                },
            }

        });

        // scrollmagic "responsabilité sociale / environnement"
        if($(window).width() >= 992) {
            let controller = new ScrollMagic.Controller();
            $repere = $("#img-large-before .col-12:first-child");
            $beforeCont = $("#img-large-before");
            let rw = $repere.width();
            if(rw < 10)
                rw = 180;
            let left = $repere.offset().left + rw;
            let width = rw * 1.5;
            let top = -($beforeCont.outerHeight() + ($beforeCont.offset().top - $repere.offset().top) - 80);
            let height = width * 4 / 3;

            // nouveau placement
            //top = $repere.offset().top;
            top = top - 80;
            left = $repere.offset().left;
            width = $repere.width();
            height = $repere.height();


            let tween = TweenMax.fromTo("#il-bg", 1, {left: left, width: width, top: top, height: height}, {
                left: 0,
                width: "100%",
                top: 0,
                height: "100%",
                ease: Sine.easeInOut
            });
            new ScrollMagic.Scene({
                "triggerElement": "#img-large",
                "triggerHook": "onEnter",
                "duration": "600"
            })
                .setTween(tween)
                .addTo(controller);
        }
    }
};