module.exports = {
    init: function () {
        console.log('localetabs init');
        // Save cookie with selected locale on tab click
        $(".a2lix_translations a.nav-link").on('click', localetabs.save);
        // if cookie set, select locale tab
        localetabs.load();

    },
    save: function (e) {
        console.log('saving locale cookie');
        Cookies.set("localetab", $(e.currentTarget).attr("href"), {expires: 0.04, path: window.location.pathname});
    },
    load: function () {
        let locale = Cookies.get("localetab");
        if(typeof locale != "undefined" && locale) {
            console.log('setting locale tab to', locale);
            $("a[href='"+locale+"']").trigger('click');
        }
        else {
            console.log('no localetab cookie setting to load from');
        }
    }
};