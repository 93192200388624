module.exports = {
    init: function () {
        let pageProduct = document.getElementsByClassName("product-page")[0];
        let proPopup = document.getElementById("pro-popup");
        let proConfirm = document.getElementById("pro-confirm");
        let proReject = document.getElementById("pro-reject");
        let getHome = document.getElementsByClassName("navbar-brand")[0].href;

        if (proConfirm) {
            if (pageProduct.classList.contains("hide")) {
                proPopup.style.display = "flex";
            }

            proReject.addEventListener("click", function () {
                document.location = getHome;
            });

            proConfirm.addEventListener("click", function () {
                let d = new Date();
                d.setTime(d.getTime() + (30*24*60*60*1000));
                let expires = "expires="+ d.toUTCString();
                //document.cookie = "checkPro=true; "+ expires +"; path=/;";
                Cookies.set('checkPro', true, { expires: 365, path: "/" });
                proPopup.style.display = "none";
                pageProduct.classList.remove("hide");
            });
        }
    }
};