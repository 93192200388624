module.exports = {
    loadedMenus: {},
    tree: null,
    activeChild: [],
    init: function () {
        $.ajax({
            url: path.tree,
            dataType: "json"

        }).done(function (data) {
            console.log('setting up product menu', data);
            productmenu.tree = data;
            productmenu.menuContainer = $(".sub-menu > .container-fluid");
            $("header .navbar-nav .nav-link").on('mouseenter', productmenu.openMenu);
            productmenu.menuContainer.on('mouseleave', productmenu.closeMenu);
            $("header").on('mouseleave', productmenu.closeMenu);
            $("#product-menu .level1 a").on('mouseover', productmenu.menu2.level1mouseover);
        });
    },
    openMenu: function (e) {
        console.log('openMenu');
        productmenu.closeMenu();

        if($(".sub-menu[data-parent='"+$(e.currentTarget).parent().attr('data-slug')+"']").length > 0) {
            productmenu.clearMenu();
            $(e.currentTarget).parent().addClass('open');
            $(".sub-menu[data-parent='"+$(e.currentTarget).parent().attr('data-slug')+"']").addClass('open');
            $("header").addClass('open');
        }
        else
            productmenu.closeMenu();
    },
    closeMenu: function () {
        $("header .navbar-nav .nav-link").parent().removeClass('open');
        $(".sub-menu").removeClass('open');
        $("header").removeClass('open');
    },
    clearMenu: function () {
        $("#product-menu").addClass("level1");
    },
    menu2: {
        level1mouseover: function(e) {
            productmenu.menu2.set($(e.currentTarget).attr('data-slug'));
        },
        set: function (slug) {
            productmenu.menu2.setActiveChild(0, slug, productmenu.tree);
            console.log('Active:', productmenu.activeChild);
            $("#product-menu .level2 #lvl2-title span").html(productmenu.activeChild[0].title);
            $("#product-menu").removeClass("level1");
        },
        setActiveChild: function (idx, slug, data) {
            for (let i = 0; i < data.children.length; i++) {
                if (data.children[i].slug == slug) {
                    productmenu.activeChild[idx] = data.children[i];
                    productmenu.menu2.setCol(idx + 1, data.children[i]);
                    break;
                }
            }
        },
        setCol: function (n, data) { // n numérotation à partir de 1
            console.log('setCol', n, data);
            // cacher les colonnes filles
            for (let i = n + 1; i <= 4; i++) {
                $("#lvl2-cat-tree > div:nth-child(" + i + ")").removeClass("open");
            }

            // créer la colonne courante
            $col = $("#lvl2-cat-tree > div:nth-child(" + n + ")");
            let link = data.external != "" ? data.external : path.prodcat + data.id+'/' + data.slug;
            let classExternal = data.external != "" ? ' class="external white" target="_blank" ' : '';
            $col.find('.col-title').html('<a href="' + link + '" title="' + data.title + '" ' + classExternal + '>' + data.title + '</a>');
            let $list = $col.find('.col-list').html('');

            for (let i = 0; i < data.children.length; i++) {
                let child = data.children[i];
                if(child.external != "") {
                    $list.append('<li><a title="' + child.title + '" href="' + child.external + '" class="external white" target="_blank" >' + child.title + '</a></li>');
                }
                else
                    $list.append('<li><a data-id="' + child.id + '" data-slug="' + child.slug + '" title="' + child.title + '">' + child.title + '</a></li>');
            }
            // ajouter les événements click
            $list.find('a').on('click', function (e) {
                $a = $(e.currentTarget);
                if(!$a.attr('href')) {
                    let idx = parseInt($a.parent().parent().parent().attr('data-col'));
                    let slug = $a.attr('data-slug');
                    $a.parent().parent().find('li').removeClass('active');
                    $a.parent().addClass('active');
                    productmenu.menu2.setActiveChild(idx + 1, slug, productmenu.activeChild[idx]);
                    console.log(idx, slug);
                }
            });

            // montrer la colonne courante + ses parents
            for (let i = 1; i <= n; i++) {
                $("#lvl2-cat-tree > div:nth-child(" + i + ")").addClass("open");
            }

        }
    },
    getMenuContentsCallback: function (slug, data) {
        productmenu.loadedMenus[slug] = data;
        $("#product-menu .level2").html(data);
    }
};