module.exports = {
    activeImg: 0,
    imgCount: 0,
    slideSpeed: 0,
    init: function () {
        // images
        this.imgCount = $(".product-img-thumblist img").length;
        $(".arrownav").on('click', productpage.prevNextImg);
        $(".thumbs img").on('click', productpage.selectImg);
        productpage.updateImg();

        // foldable
        productpage.foldable.init();

        // tabs
        $(".tab-group").each(function () {
            let i = 1;
            $(this).find(".tab-heads .col").each(function () {
                $(this).attr('data-idx', i);
                i++;
            })
        });

        // thumblist
        productpage.thumblist.init();

        $("#pdt-tabcontent .card-header a[data-toggle='collapse']").on('click', function(e) {
            console.log("clicking");
            $element = $(e.currentTarget);
            if($element.attr("aria-expanded") == "false") {
                console.log("expanding");
                $previous = $element.parent().parent().parent().prev();
                console.log("previous : ", $previous.length);
                if($previous.length > 0) {
                    $([document.documentElement, document.body]).animate({
                        scrollTop: $previous.offset().top
                    }, 300);
                }
            }
        });
    },

// images
    prevNextImg: function (e) {
        if ($(e.currentTarget).hasClass('prev'))
            productpage.activeImg--;
        else
            productpage.activeImg++;
        if (productpage.activeImg < 0)
            productpage.activeImg = productpage.imgCount - 1;
        else if (productpage.activeImg >= productpage.imgCount)
            productpage.activeImg = 0;
        productpage.thumblist.updateMargin();
        productpage.updateImg();
    },
    selectImg: function (e) {
        productpage.activeImg = parseInt($(e.currentTarget).attr('data-idx')) - 1;
        productpage.updateImg();
    },
    updateImg: function () {
        $thumb = $(".thumbs img:nth-child(" + (productpage.activeImg + 1) + ")");
        $(".product-img img").attr('src', $thumb.attr('data-img'));
        $(".thumbs img").removeClass("active");
        $thumb.addClass("active");
    },
// foldable
    foldable: {
        init: function() {
            $(".description h2").each(function () {
                $container = $("<div class='fold'></div>");
                $container.append($(this).nextUntil('h2').detach());
                $(this).after($container);
                $(this).append('<span></span>');
            })
            $(".description h2").on('click', productpage.foldable.toggle);
            $(".description h2:first-child").trigger('click');
            setTimeout(function () {
                productpage.slideSpeed = 300;
            }, 300);
        },
        toggle: function (e) {
            $(".description .fold").slideUp(productpage.slideSpeed);
            $(".description h2").removeClass('active');
            if (e) {
                $(e.currentTarget).addClass('active');
                $(e.currentTarget).next().slideDown(productpage.slideSpeed);
            }
        }
    },

    thumblist: {
        init: function () {
            console.log('tlinit');
            var h = $(".product-img img").outerHeight();
            if(!h || h < $(".product-img").width()) {
                //setTimeout(productpage.thumblist.init, 500);
                console.log('waiting for img load');
                $(".product-img img").on('load', productpage.thumblist.init);
            }
            $(".thumbs").css('height', 'calc(' + h + 'px - 42px - 2em)');
        },
        updateMargin: function () {
            $firstImg = $(".thumbs img:first-child");
            $imgContainer = $(".thumbs .slide");
            var step = $firstImg.height() + 14;

            maxSteps = Math.floor($(".thumbs").innerHeight() / step);
            overstep = productpage.activeImg - maxSteps + 1;

            maxHeight = $(".thumbs").innerHeight() - step;

            console.log("activeImg", productpage.activeImg, " - maxsteps", maxSteps, " - step", step);
            if (productpage.activeImg * step > maxHeight) {
                console.log('overstep', overstep);
                $imgContainer .css('margin-top', '-' + overstep * step + 'px');
            }
            else {
                console.log('overstep 000');
                $imgContainer .css('margin-top', '0');
            }
        }
    }
};